<template>
    <div class='adiponectin'>
        <div class="userHead">
            <div class="headImg">
                <img class="img" :src="headUrl" alt="">
            </div>
            <div class="basicData">
                <div class="userinfo">
                    <div class="infoItem" v-for="(item, index) in userData" :key="index">
                        <div class="label">
                            <div class="tipIcon"></div>
                            <div>{{item.label}}</div>
                        </div>
                        <div>{{reportData[item.value]}}</div>
                    </div>
                </div>
                <div class="userinfo">
                    <div class="infoItem" v-for="(item, index) in userData2" :key="index">
                        <div class="label">
                            <div class="tipIcon"></div>
                            <div>{{item.label}}</div>
                        </div>
                        <div>{{reportData[item.value]}}</div>
                    </div>
                </div>
            </div>
            <div class="paging">
                <div class="pagingImg">
                    <img class="img" :src="pagUrl" alt="">
                </div>
            </div>
        </div>
        <!-- 结果分析 -->
        <div class="result" v-for="(item, index) in resultData" :key="index">
            <div class="headTitle">{{item.title}}</div>
            <div class="contSty">
                <div class="content" v-for="(el, i) in item.modular" :key="i">
                    <div v-if="el.subTitle" :class="el.radius ? 'subTitle radius' : 'subTitle'">{{el.subTitle}}</div>
                    <div class="ordinary" v-if="el.type == 'text'">
                        <div :class="el.indent ? 'textCont' : ''" v-for="(te, ix) in el.content" :key="ix">{{te}}</div>
                    </div>
                    <!-- 比例表格 -->
                    <div class="teble" v-if="el.type == 'table'">
                        <div class="th">
                            <div class="tr" v-for="(th, ix) in el.thconfig" :key="ix" :style="{'flex-grow': th.grow}">
                                <div>{{th.label}}</div>
                                <div>{{th.translation}}</div>
                            </div>                        
                        </div>
                        <div class="td" v-for="(td, k) in el.content" :key="k">
                            <div class="tr">
                                <div>{{td.project}}</div>
                                <div>{{td.translation}}</div>
                            </div>
                            <div class="tr grow">
                                <div class="trItem" v-for="(cor, l) in td.result" :key="l" :style="{'backgroundColor': cor}"></div>
                                <div class="ballImg" :style="{'left': td.position}">
                                    <img class="img" :src="indball" alt="">
                                </div>
                            </div>
                            <div class="tr" :style="{'color': td.color}">
                                <div>{{td.analysis}}</div>
                                <div>{{td.translation2}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 普通表格 -->
                    <div class="teble" v-if="el.type == 'ordTable'">
                        <div class="th">
                            <div class="tr" v-for="(th, ix) in el.thconfig" :key="ix" :style="{'flex-grow': th.grow}">
                                <div>{{th.label}}</div>
                                <div>{{th.translation}}</div>
                            </div>                        
                        </div>
                        <div class="td" v-for="(td, k) in el.content" :key="k">
                            <div class="tr" :style="{'color': td.color}">
                                <div>{{td.type}}</div>
                            </div>
                            <div class="tr red" v-if="td.grade">
                                <div>{{td.grade}}</div>
                            </div>
                            <div class="tr red" v-if="td.risk">
                                <div>{{td.risk}}</div>
                            </div>
                            <div class="tr" v-if="td.explain">
                                <div>{{td.explain}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 说明表格 -->
                    <div class="teble explain" v-if="el.type == 'tipTable'">
                        <div class="td" v-for="(td, k) in el.content" :key="k">
                            <div class="tr trIcon">
                                <div class="icon" :style="{'opacity': td.opacity}"></div>
                                <div>{{td.risk}}</div>
                            </div>
                            <div class="tr">
                                <div>{{td.level}}</div>
                            </div>
                            <div class="tr grow3">
                                <div class="faitem" v-for="(de, e) in el.degreeArr" :key="e">
                                    <div :class="e >= td.degree  ? 'trItem' : 'trItem bgred'" ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 检查表格 -->
                    <div class="teble explain" v-if="el.type == 'testTable'">
                        <div class="th">
                            <div class="tr" v-for="(th, ix) in el.thconfig" :key="ix" :style="{'flex-grow': th.grow}">
                                <div>{{th.label}}</div>
                                <div>{{th.translation}}</div>
                            </div>                        
                        </div>
                        <div class="td" v-for="(td, k) in el.content" :key="k">
                            <!-- <div class="tr">
                                <div>{{td.site}}</div>
                            </div> -->
                            <div class="tr">
                                <div>{{td.gene}}</div>
                            </div>
                            <div class="tr">
                                <div>{{td.allelic1}}</div>
                            </div>
                            <div class="tr">
                                <div>{{td.Allelic2}}</div>
                            </div>
                            <div class="tr">
                                <div>{{td.result}}</div>
                            </div>
                            <div class="tr">
                                <div>{{td.describe}}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="special" v-if="el.type == 'textSpecial'">
                        <div class="spText" v-for="(te, ix) in el.content" :key="ix">
                            <div :style="{'color': te.color}">{{te.title}}</div>
                            <div class="textCont" v-for="(k, o) in te.info" :key="o" >{{k}}</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="leptinImg">
                <img class="img" :src="leptin" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { adiponectinReport } from '@/api/index'

export default {
    components: {
        
    },
    data() {
        return {
            headUrl: `${this.$imgDomain}/www/HealthingCore/adiponectin.png`,
            pagUrl:`${this.$imgDomain}/www/HealthingCore/paging.png`,
            leptin:`${this.$imgDomain}/www/HealthingCore/leptin.png`,
            indball:`${this.$imgDomain}/www/HealthingCore/indball.png`,
            reportData: {},
            userData: [
                {
                    label: '姓名：',
                    value: 'name'
                },
                {
                    label: '性别：',
                    value: 'sex'
                },
                {
                    label: '出生年月：',
                    value: 'birthday'
                },
            ],
            userData2: [
                {
                    label: '注册日期：',
                    value: 'ReportTime'
                },
                {
                    label: '登记编号：',
                    value: 'DataValue'
                }
            ],
            resultData: [
                {
                    title: '瘦素＆脂联素结果分析',
                    modular: [
                        {
                            subTitle:'瘦素&脂联素(Leptin&Adiponectin)',
                            radius: true,
                            type: 'text',
                            indent: true,
                            content: [
                                '瘦素（Leptin）是一种由脂肪组织分泌的激素，它在血清中的含量与动物脂肪组织大小成正比。瘦素作用于位于中枢神经系统的受体（Leptin Receptor）从而调控生物的行为以及新陈代谢。当动物体的体脂减少或处于低能量的状态下（例如饥饿），血清中瘦素的含量会明显下降，从而激发动物的觅食行为，同时降低自身能量消耗。反之，当生物体的体脂增加时，血清中瘦素含量升高，进而抑制进食并且加速新陈代谢。瘦素就是通过这样的负反馈机制来调控生物体的能量平衡以及体重。',
                                '脂肪组织（adipose tissue）主要由大量聚集成团的脂肪细胞构成，脂联素(Adiponectin/ADPN) 是脂肪细胞分泌的一种内源性生物活性多肽或蛋白质。脂联素是一种胰岛素增敏激素(AnInsulin-sensitizing Hormone)，能改善小鼠的胰岛素抗性(Insulin resistance)和动脉硬化症；对人体的研究发现，脂联素水平能预示II型糖尿病和冠心病的发展，并在临床试验表现出抗糖尿病、抗动脉粥样和炎症的潜力。'
                            ]
                        },
                        {
                            subTitle:'检测结果(Testing Result)',
                            type: 'table',
                            thconfig: [
                                {
                                    label: '项目名称',
                                    translation: '(Items)'
                                },
                                {
                                    label: '结果',
                                    translation: '(Results)',
                                    grow: '2'
                                },
                                {
                                    label: '结果分析',
                                    translation: '(Analysis)'
                                }
                            ],
                            content: [
                                {
                                    project: '瘦素',
                                    translation: '(Leptin)',
                                    analysis: '',
                                    translation2: '(Flat)',
                                    position: '0',
                                    result: [
                                        '#E87366',
                                        '#74C63E',
                                        '#E87366',
                                    ]
                                },
                                {
                                    project: '脂联素',
                                    translation: '(Adiponectin)',
                                    analysis: '',
                                    translation2: '(Normal)',
                                    position: '0',
                                    result: [
                                        '#E87366',
                                        '#FFDA00',
                                        '#74C63E',
                                        '#E87366',
                                    ]
                                },
                                {
                                    project: '胰岛素抵抗',
                                    translation: '(Insulin)',
                                    analysis: '',
                                    translation2: '(Metaphase)',
                                    position: '0',
                                    result: [
                                        '#74C63E',
                                        '#FFDA00',
                                        '#E87366',
                                    ]
                                }
                                ,
                                {
                                    project: '糖尿病风险',
                                    translation: '(Diabetic)',
                                    analysis: '',
                                    translation2: '(Normal)',
                                    position: '0',
                                    result: [
                                        '#74C63E',
                                        '#FFDA00',
                                        '#E87366',
                                    ]
                                }
                            ]
                        }
                        
                    ]
                },
                {
                    title: '瘦素＆脂联素结果分析',
                    modular: [
                        {
                            subTitle:'结果分析(Result analysis)',
                            type: 'ordTable',
                            thconfig: [
                                {
                                    label: '项目名称',
                                    translation: '(Items)'
                                },
                                {
                                    label: '结果分析',
                                    translation: '(Analysis)',
                                },
                            ],
                            content: []
                        }
                    ]
                },
                {
                    title: '脂质代谢能力结果分析',
                    modular: [
                        {
                            subTitle:'脂质代谢能力(Lipid metabolic capacity)',
                            radius: true,
                            type: 'text',
                            indent: true,
                            content: [
                                '脂肪代谢是体内重要且复杂的生化反应，指生物体内脂肪，在各种相关酶的帮助下，消化吸收、合成与分解的过程，加工成机体所需要的物质，保证正常生理机能的运作，对于生命活动具有重要意义。脂类是身体储能和供能的重要物质，也是生物膜的重要结构成分。脂肪代谢异常引发的疾病'
                            ]
                        },
                        {
                            subTitle:'检测结果(Testing Result)',
                            type: 'ordTable',
                            thconfig: [
                                {
                                    label: '疾病种类',
                                    translation: '(Type of disease)'
                                },
                                {
                                    label: '等级',
                                    translation: '(Grade)',
                                },
                                {
                                    label: '类别',
                                    translation: '(Category)'
                                }
                            ],
                            content: [
                                {
                                    type: '脂质代谢能力',
                                    grade: '',
                                    risk: '',
                                },
                            ]
                        },
                        {
                            subTitle:'阅读说明(Read the instructions)',
                            type: 'tipTable',
                            degreeArr: ['','','','','',],
                            content: [
                                {
                                    risk: '高风险',
                                    level: '5级',
                                    opacity: '1',
                                    degree: '5',
                                },
                                {
                                    risk: '较高风险',
                                    level: '4级',
                                    opacity: '0.8',
                                    degree: '4',
                                },
                                {
                                    risk: '中等风险',
                                    level: '3级',
                                    opacity: '0.6',
                                    degree: '3',
                                },
                                {
                                    risk: '较低风险',
                                    level: '2级',
                                    opacity: '0.4',
                                    degree: '2',
                                },
                                {
                                    risk: '低风险',
                                    level: '1级',
                                    opacity: '0.2',
                                    degree: '1',
                                },
                            ]
                        },
                        {
                            subTitle:'检查位点(Detection site)',
                            type: 'testTable',
                            thconfig: [
                                // {
                                //     label: '检测位点',
                                // },
                                {
                                    label: '基因',
                                },
                                {
                                    label: '等位1',
                                },
                                {
                                    label: '等位2',
                                },
                                {
                                    label: '检测结果',
                                },
                                {
                                    label: '结果描述',
                                }
                            ],
                            content: [
                                {
                                    site: 'GWV0002119',
                                    gene: 'LDLR',
                                    allelic1: 'G',
                                    Allelic2: 'T',
                                    result: 'G/G',
                                    describe: ''
                                },
                                {
                                    site: 'GWV0002119',
                                    gene: 'FTO',
                                    allelic1: 'G',
                                    Allelic2: 'T',
                                    result: 'C/C',
                                    describe: ''
                                },
                            ]
                        },
                       
                    ]
                },
                {
                    title: '脂质代谢能力结果分析',
                    modular: [
                        {
                            subTitle:'基因功能介绍(Results)',
                            type: 'textSpecial',
                            radius: true,
                        },
                        {
                            subTitle:'相关基因(Related gene)',
                            type: 'textSpecial',
                            content: [
                                {
                                    title: '1: 肥胖基因(FTO)',
                                    color: '#E87366',
                                    info: [
                                        'FTO基因，又称脂肪量和肥胖相关基因，位于染色体16q12.2，包含8个外显子和9个内含子，长度为430Kb，编码FTO蛋白属于2-氧戊二酸依赖性核酸脱甲基酶，属于非血红素加双氧酶超家族的一个成员，具有影响摄食及维持能量代谢平衡的作用。FTO基因能够使DNA去甲基化并参与DNA修复、脂肪酸代谢和翻译后修饰。',
                                        'FTO基因是全基因组关联研究(GWAS)确定并发表的第一个肥胖易感位点，其作为摄食行为的关键介质，与抗动脉粥硬化、抗高血糖、抗炎脂肪因子和血清脂联素水平显著相关',
                                        'FTO基因中存在许多SNPs，其中与肥胖相关的FTO等位基因位于第一个内含子，第一个内含子由10个SNPs组成，有三种基因型（CC、CT和TT），其中C等位基因是肥胖风险等位基因。'
                                    ]
                                },
                                {
                                    title: '2：心血管疾病风险基因(LDLR)',
                                    color: '#E87366',
                                    info: [
                                        'LDLR基因对于调节胆固醇的体内平衡，调节血浆总胆固醇浓度起关键性作用。LDLR主要功能将胆固醇酯水解成游离胆固醇和脂肪酸，在胆固醇代谢过程中发挥重要作用，当LDLR基因风险高，心脑血管疾病风险就会升高。',
                                        '①生理功能：LDLR是一种细胞表面糖蛋白，对于调节胆固醇的体内平衡，调节血浆总胆固醇浓度起关键性作用。LDLR 主要功能是经 LDL 受体途径，通过摄取胆固醇进入细胞内，用于细胞增殖和固醇类激素及胆汁酸盐合成，在胆固醇代谢过程中发挥重要作用。LDL 与细胞膜表面 LDLR结合，被吞入细胞，LDL 经溶酶体酶作用，其中胆固醇酯水解成游离胆固醇和脂肪酸。',
                                        '②表达部位：广泛存在于体内组织细胞中，如成纤维细胞、平滑肌细胞、血管内皮细胞、肾上腺和肝脏等，其中以肝细胞含量最多。它在肝脏、肾上腺皮质、睾丸、卵巢等甾源性组织脂蛋白代谢中发挥重要作用，但其在各组织或细胞中活性差别较大。',
                                        '③LDLR 多态性与血脂紊乱的关系：LDLR 对于调节胆固醇的体内平衡，调节血浆总胆固醇浓度起关键性作用，LDLR 基因发生突变，可引起 LDL 受体数目降低或受体蛋白结构发生改变，不再能与 LDL 结合或阻止其进入细胞内，最终导致血浆胆固醇水平显著上升。'
                                    ]
                                },
                            ]
                        }     
                    ]
                },
                {
                    title: '健康指导建议',
                    modular: [
                        {
                            subTitle:'重塑原本健康的你',
                            type: 'textSpecial',
                            radius: true,
                            content: [
                                {
                                    title: '• 饮食建议',
                                    color: '#E87366',
                                    info: [
                                        '膳食中影响血脂的因素包括饱和脂肪酸、不饱和脂肪酸、胆固醇和总热量，其中最主要的是饱和脂肪酸和胆固醇的摄入量。',
                                        '1.血脂偏高的人应该少吃或不吃动物内脏、蛋黄等胆固醇含量极高的食物，控制饮食中的胆固醇摄入（每天少于300 毫克）。',
                                        '2. 血脂偏高的人少吃肥肉和荤油，减少饱和脂肪的摄入。',
                                        '3. 血脂偏高的人多吃蔬菜水果和菌藻类食物。因为这些食物含有丰富的膳食纤维有助于胆固醇的排泄。',
                                        '4. 血脂偏高的人可在日常饮食中与豆油、花生油等植物油搭配食用。'
                                    ]
                                },
                                {
                                    title: '• 食物选择',
                                    color: '#E87366',
                                    info: [
                                        '根据需要选择不同胆固醇含量的食物：',
                                        '1. 不含胆固醇的食物，包括硬壳果类，如杏仁、核桃。五谷类，蛋白水果类，果酱果冻。此外还有蔬菜类，花生、花生酱、植物性油脂及人造奶油、面筋、豆类与豆浆、豆腐等豆制品。',
                                        '2. 高胆固醇食物如：蛋黄、鱼子、动物脑、动物肝肾、墨斗鱼（乌贼）、蟹黄、蟹膏等。'
                                    ]
                                },
                                {
                                    title: '• 避免酗酒',
                                    color: '#E87366',
                                    info: [
                                        '长期大量酗酒可造成甘油三酯水平升高。'
                                    ]
                                },
                                {
                                    title: '• 避免吸烟',
                                    color: '#E87366',
                                    info: [
                                        '香烟中含大量的有害物质，如尼古丁、煤焦油、苯并芘、重金属等等多大几十种有害物质，避免吸烟以及减少呆在二手烟环境中可减少上述有害物质对血管上皮细胞的损伤，避免血脂在损伤处沉积。'
                                    ]
                                },
                                {
                                    title: '• 运动',
                                    color: '#E87366',
                                    info: [
                                        '保持规律的运动习惯：',
                                        '1. 经常运动可以降低低密度脂蛋白胆固醇的水平，升高高密度脂蛋白胆固醇的水平。',
                                        '2. 规律的运动：',
                                        '成年人：每周至少 150 分钟的中等强度或 75 分钟的剧烈运动（或两者结合）。',
                                        '儿童和青少年： 每天至少 1 小时的中等强度或剧烈活动，每周至少 3 天。'
                                    ]
                                },
                                {
                                    title: '• 控制体重',
                                    color: '#E87366',
                                    info: [
                                        '超重或肥胖均会增加血液中血脂的含量，对体内脂质的代谢产生一定的影响。'
                                    ]
                                },
                                {
                                    title: '• 心理健康',
                                    color: '#E87366',
                                    info: [
                                        '长时间的精神压力可引起血脂的升高。'
                                    ]
                                }
                            ]
                        }     
                    ]
                },
            ]

        };
    },
    props:{
        
    },
    created() {
        
    },
    mounted() {
        this.getAdiponectinReport();
    },
    methods: {
        getAdiponectinReport(){
            const formData = new FormData();
            const autoid = this.$query('autoid');
            formData.append('action', 'GetUserzlsExpands');
            formData.append('autoid', autoid);
            adiponectinReport(formData).then(res => {
                const {result} = res;
                if(result.length > 0){
                    this.reportData = result[0];
                    this.logicalProcessing(result[0]);
                    this.lipidMetabolism(result[0]);
                } else {
                    this.$toast('暂无报告数据')
                }
            })
        },
        // 瘦素&脂联素检测结果处理
        logicalProcessing(data){
            // 瘦素
            const sxtarget = data.sxtarget;
            // 脂联素
            const zlstarget = data.zlstarget;
            // 性别
            const sex = data.sex;
            // 检测结果
            let leptin = this.resultData[0].modular[1].content[0]; //瘦素结果
            let adiponectin = this.resultData[0].modular[1].content[1]; //脂联素结果
            let insulin = this.resultData[0].modular[1].content[2]; //胰岛素结果
            let Diabetes = this.resultData[0].modular[1].content[3]; //糖尿病结果
            // 结果分析解释
            let explain = this.resultData[1].modular[0].content;
            // 瘦素
            if(sex == '男'){
                if(sxtarget < 3.2) {
                    // 偏低 红色
                    leptin.position = '14%';
                    leptin.color = '#E87366';
                    leptin.analysis = '偏低';
                    leptin.translation2 = '(Low)';
                } else if(sxtarget >= 3.2 && sxtarget <= 6){
                    // 正常
                    leptin.position = '47%';
                    leptin.analysis = '正常';
                    leptin.translation2 = '(Low)';
                } else {
                    // 偏高 红色
                    leptin.position = '81%';
                    leptin.color = '#E87366';
                    leptin.analysis = '偏高';
                    leptin.translation2 = '(Low)';
                }
            } else {
                if(sxtarget < 3.2) {
                    // 偏低 红色
                    leptin.position = '14%';
                    leptin.analysis = '偏低';
                } else if(sxtarget >=3.2 && sxtarget <= 6){
                    // 正常
                    leptin.position = '47%';
                    leptin.analysis = '正常';
                } else {
                    // 偏高
                    leptin.position = '81%';
                    leptin.analysis = '偏高';
                }
            }

            // 脂联素
            if(zlstarget <= 10) {
                // 偏低 红色
                adiponectin.position = '10%';
                adiponectin.color = '#E87366';
                adiponectin.analysis = '偏低';
                adiponectin.translation2 = '(Low)';
            } else if(zlstarget > 10 && zlstarget <= 18){
                // 黄色
                adiponectin.position = '35%';
                adiponectin.color = '#FFDA00';
                adiponectin.analysis = '中风险';
                adiponectin.translation2 = '(MediumRisk)';
            } else if(zlstarget > 18 && zlstarget < 30){
                // 正常
                adiponectin.position = '60%';
                adiponectin.analysis = '正常';
                adiponectin.translation2 = '(normal)';
            } else {
                // 偏高 红色
                adiponectin.position = '85%';
                adiponectin.color = '#E87366';
                adiponectin.analysis = '偏高';
                adiponectin.translation2 = '(high)';
            }

            // 胰岛素
            if(zlstarget < 13) {
                // 中期
                insulin.position = '81%';
                insulin.color = '#FFDA00';
                insulin.analysis = '高风险';
                insulin.translation2 = '(Metaphase)';
            } else if(zlstarget >= 13 && zlstarget <= 20){
                // 中期
                insulin.position = '47%';
                insulin.color = '#FFDA00';
                insulin.analysis = '中风险';
                insulin.translation2 = '(Metaphase)';
            } else {
                // 正常
                insulin.analysis = '正常';
                insulin.position = '14%';
                insulin.translation2 = '(normal)';
            }
            
            // 糖尿病
            if(zlstarget < 10) {
                // 高风险
                Diabetes.position = '81%';
                Diabetes.analysis = '高风险';
                Diabetes.color = '#E87366';
                Diabetes.translation2 = '(HighRisk)';
            } else if(zlstarget >= 10 && zlstarget <= 15){
                // 中风险
                Diabetes.position = '47%';
                Diabetes.color = '#FFDA00';
                Diabetes.analysis = '中风险';
                Diabetes.translation2 = '(MediumRisk)';
            } else {
                // 低风险
                Diabetes.position = '12%';
                Diabetes.analysis = '低风险';
                Diabetes.translation2 = '(LowRisk)';
            }

            // 结果分析解释
            const leptinState = leptin.analysis //瘦素状态
            const adiponectinState = adiponectin.analysis //脂联素状态
            const insulinState = insulin.analysis //胰岛素状态
            // 瘦素解释
            if(leptinState == '偏低') {
                explain.push({
                    type: '瘦素',
                    color: '#E87366',
                    explain: '瘦素偏低，瘦素由脂肪细胞分泌，通过作用于大脑和胰岛细胞对机体的进食和能量代谢等进行调，受检者瘦素检测结果低于参考范围，可能由于瘦素分泌障碍、内分泌失调、睡眠不规律等因素；'
                })
            } else if(leptinState == '偏高'){
                explain.push({
                    type: '瘦素',
                    color: '#E87366',
                    explain: '瘦素偏高，瘦素由脂肪细胞分泌，通过作用于大脑和胰岛β细胞对机体的进食和能量代谢等进行调节。受检者瘦素检测结果高于检测范围，可能由于脂肪增加、胰岛素抵抗、瘦素抵抗等因素所致；'
                })
            } else {
                explain.push({
                    type: '瘦素',
                    explain: '正常'
                })
            }
            // 脂联素解释
            if(adiponectinState == '偏低') {
                explain.push({
                    type: '脂联素',
                    color: '#E87366',
                    explain: '脂联素偏低，脂联素是脂肪细胞分泌的一类蛋白因子，它能影响机体的脂肪的糖类代谢、血管内皮分泌等，受检者脂联素检测结果低于参考范围，可能由于脂联素分泌障碍，脂联素相应基因表达减少等因素所导致。'
                })
            } else if(adiponectinState == '偏高'){
                explain.push({
                    type: '脂联素',
                    color: '#E87366',
                    explain: '脂联素偏高，脂联素是脂肪细胞分泌的一类蛋白因子，它能影响机体的脂肪的糖类代谢、血管内皮分泌等，受检者脂联素检测结果高于参考范围，可能由于体内糖和脂肪代谢旺盛以及体内存有炎症反应等因素所导致。'
                })
            } else {
                explain.push({
                    type: '脂联素',
                    explain: '正常'
                })
            }
            // 胰岛素解释
            if(insulinState == '中风险') {
                explain.push({
                    type: '胰岛素抵抗',
                    color: '#FFDA00',
                    explain: '胰岛素抵抗中风险，胰岛素抵抗是代谢综合征的基本特征，易出现:疲之、容易饥饿、减重困难、渴望甜食，饭后疲倦、偏头痛、血脂代谢异常和脂肪沉积，腹型肥胖，月经紊乱。'
                })
            } else if(insulinState == '高风险') {
                explain.push({
                    type: '胰岛素抵抗',
                    color: '#FFDA00',
                    explain: '胰岛素抵抗高风险，胰岛素抵抗是代谢综合征的基本特征，易出现:疲之、容易饥饿、减重困难、渴望甜食，饭后疲倦、偏头痛、血脂代谢异常和脂肪沉积，腹型肥胖，月经紊乱。'
                })
            } else {
                explain.push({
                    type: '胰岛素抵抗',
                    explain: '正常'
                })
            }
        },
        // 纸质代谢结果处理
        lipidMetabolism(data){
            // 检测结果
            let result = this.resultData[2].modular[1].content[0];
            // 检测站点
            let LDLR = this.resultData[2].modular[3].content[0];
            let FTO = this.resultData[2].modular[3].content[1];
            LDLR.describe = data.ldlr != '0' ? data.ldlr : '正常';
            FTO.describe = data.fto != '0' ? data.fto : '正常';
            if(data.ldlr == '异常' && data.fto == '异常') {
                // 较高风险
                result.risk = '较高风险';
                result.grade = '4级';
            } else if((data.ldlr == '异常' && data.fto == '正常') || (data.ldlr == '正常' && data.fto == '异常')){
                // 中等风险
                result.risk = '中等风险';
                result.grade = '3级';
            } else {
                // 较低风险
                result.risk = '较低风险';
                result.grade = '2级';
            }
        }
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.adiponectin {
    width: 592.28px;
    padding: 0 10px;
    .userHead {
        min-height: 860px;
        position: relative;
        .headImg {
            width: 85%;
            padding-top: 50px;
        }
        .basicData {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .userinfo {
                border-bottom: 1px solid #ccc;
                display: flex;
                justify-content: space-around;
                width: 80%;
                font-size: 14px;
                .infoItem {
                    display: flex;
                    align-items: center;
                    padding-bottom: 5px;
                    margin-top: 20px;
                    .label {
                        display: flex;
                        align-items: baseline;
                        .tipIcon {
                            width: 10px;
                            height: 10px;
                            background-color: #595757;
                            margin-right: 5px;
                        }
                    }
                    
                }
            }
        }
        .paging {
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 20px;
            .pagingImg {
                width: 20%;
            }
        }
    }
    .result {
        padding-bottom:20px;
        margin-top: 40px;
        height: 860px;
        position: relative;
        .headTitle {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 700;
            border-bottom: 2px solid #5CC2DC;
            height: 40px;
            margin-bottom: 5px;

        }
        .contSty {
            background: #F9F9F9;
            height: calc(100% - 70px);
            border-radius: 0 0 15px 15px;
        }
        .content {
            color: #595757;
            .subTitle {
                font-size: 14px;
                font-weight: 700;
                color: #fff;
                background: #A0A0A0;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .radius {
                border-radius: 10px 10px 0 0;
                background: #4EA2C3;
            }
            .ordinary {
                padding: 20px 10px;
                font-size: 14px;

                .textCont{
                    text-indent:2em;
                }
            }
            .special {
                padding: 0 10px;
                .spText {
                    margin-top: 10px;
                    font-size: 14px;
                    .textCont{
                        text-indent:2em;
                    }
                }   
            }
            
            .teble {
                .th {
                    display: flex;
                    justify-content: space-around;
                    .tr:last-child{
                        border-right: none;
                        flex: 1;
                    }
                }
                .tr {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    // height: 50px;
                    padding: 10px;
                    width: 20%;
                    // flex: 1;
                }
                .td {
                    display: flex;
                    justify-content: space-around;
                    .grow,
                    .grow3{
                        flex-grow: 2;
                        flex-direction: row;
                        position: relative;
                        .trItem {
                            height: 20px;
                            width: 100%;
                        }
                        .ballImg {
                            position: absolute;
                            width: 15px;
                            height: 15px;
                        }
                    }
                    .grow3 {
                        flex-grow: 4;
                        .faitem {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            border-right: 1px solid #ccc;
                            align-items: center;
                            .trItem {
                                background: #A0A0A0;
                                height: 10px;
                            }
                            .trItem:last-child {
                                border-right: none;
                            }
                        }
                        
                    }
                    .trIcon {
                        flex-direction: row;
                        .icon {
                            width: 10px;
                            height: 10px;
                            border-radius: 10px;
                            background: #4EA2C3;
                            margin-right: 2px;
                        }
                    }
                    .tr:last-child{
                        border-right: none;
                        flex: 1;
                    }
                }
                .red {
                    color: #E87366;
                }
                .bgred {
                    background: #E87366 !important;
                }
                
            }
            
            .explain {
                .tr {
                    height: 30px;
                }
            }
        }
        .leptinImg{
            position: absolute;
            bottom: 20px;
            height: 10px;
        }
    }
}
</style>